import React from "react";
import Quotes from '../../../static/images/quotes.png';
import PropTypes from "prop-types";

const StoryCard = ({ review, onVideoClick }) => (
    <div>
        <div className="pd-10 pointer" onClick={onVideoClick.bind(this, review.video_id)}>
            <img src={review.video_image} width="100%" alt="video" className="border-radius-5" />
        </div>
        {review.image ?
            <div className="newsroom-video-logo-div">
                <img src={review.image} className="newsroom-logo" alt="logo" />
            </div>
            : <div className="pd-top-15"></div>}
        <div className="columns flex-wrap pd-left-7">
            <div className="column is-1 is-2-mobile pd-bottom-10 mg-left-20">
                <img src={Quotes} width="25px" alt="quotes" className="absolute is-hidden-mobile" />
                <img src={Quotes} width="25px" alt="quotes" className="is-hidden-tablet" />
            </div>
            <div className="column is-11 is-10-mobile font-14 no-pd-top-mobile height-200">
                {review.review}
                <p className="is-blue-text font-13 has-text-weight-bold"><br />{review.by}{review.remove_comma ? '' : ','}<br />{review.designation}</p>
            </div>
        </div>
    </div>
)

StoryCard.propTypes = {
    review: PropTypes.object.isRequired,
}

export default StoryCard