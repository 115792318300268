import React, { Component } from 'react';
import GE from '../../../static/images/ge.png';
import MMT from '../../../static/images/mmt.png';
import JUBI from '../../../static/images/jubilant.png';
import NEARBUY from '../../../static/images/nearbuy.png';
import FAREPORTAL from '../../../static/images/fareportal.png';
import IIFL from '../../../static/images/iifl.png';
import LENOVO from '../../../static/images/lenovo.png';
import MYNTRA from '../../../static/images/myntra.png';
// import KNOWLARITY from '../../../static/images/knowlarity.png';
import YUM from '../../../static/images/yum.png';
import PUMA from '../../../static/images/puma.png';
import deep from '../../../static/images/deep.png';
import biplob from '../../../static/images/biplob.png';
import terri from '../../../static/images/terri.png';
import ankur from '../../../static/images/ankur.png';
import sam from '../../../static/images/sam.png';
import anand from '../../../static/images/anand.png';
import abhishek_sen from '../../../static/images/abhishek_sen.png';
import tauseef from '../../../static/images/tauseef.png';
import manisha from '../../../static/images/manisha.png';
import rohit_sandal from '../../../static/images/rohit_sandal.png';
import StoryCard from '../reusable/StoryCard';
import VideoModal from '../reusable/VideoModal';
import deep3 from '../../../static/images/rajeev.png'
import SL from '../../../static/images/Sun_Life_logo.png'
import mark from '../../../static/images/mediacom2.png'
import MediaCom from '../../../static/images/MediaCom-Logo.png'
import harmeen from '../../../static/images/airtelamberV.1.png'
import Airtel from '../../../static/images/Airtel-Logo-PNG-Image-715x269.png'
import rohit from '../../../static/images/groupM.png'
import Group_M from '../../../static/images/groupM_logo.png'
import manuel from '../../../static/images/altimetric.png'
import Altimetric from '../../../static/images/altimetric_logo.png'
import Geetha from '../../../static/images/infeedogeeta.png'
import geeta_img from '../../../static/images/vgdreportinfeedologoV.1-59.png'
import EvalUser from '../../../static/images/evaluserveiconV.1.png'
import EVS_LOGO from '../../../static/images/EVS_LOGO.png'
import bbc_video from '../../../static/images/BBC_VIDEO.png'
import lenskart_logo from '../../../static/images/LENSKART_LOGO.png'
import Tiger_Genpact from '../../../static/images/TigerGenpact.png'
import Genpact_logo from '../../../static/images/Genpact_logo.png'
import mahesh_sg from '../../../static/images/mahesh-society-generale.png'
import society_generale_logo from '../../../static/images/society-generale.png'
import aditya_kohli_clix from '../../../static/images/aditya-kohli-clix.png'
import clix_logo from '../../../static/images/clix-logo.png'
import himanshu_gradeup from '../../../static/images/himanshu-gradeup.png'
import gradeup_logo from '../../../static/images/gradeup-logo.jpeg'

export default class CustomersStories extends Component {
    state = {
        reviews: [
            {
                image: GE,
                review: 'Interacting with [Amber] is done so well, it feels human, it feels natural, it feels like I am talking to someone who is listening to me.',
                by: 'Terri Bresenham',
                designation: 'President & CEO',
                video_image: terri,
                video_id: 'pwGG0eEk-L4'
            },
            {
                image: MediaCom,
                review: 'Amber has personally help me counsel several people with a range of issues that they would have otherwise  had no one to talk to about.',
                by: 'Sonia and Mark',
                designation: 'CHRO & CEO',
                video_image: mark,
                video_id: 'njl0LQaVOAc'
            },
            {
                image: LENOVO,
                review: '90% of employees who Amber connects [with] respond, which I thought was a great win. People are comfortable talking to Amber; they are comfortable sharing their thoughts and what they are going through.',
                by: 'Rohit Sandal',
                designation: 'CHRO',
                video_image: rohit_sandal,
                video_id: '1f5vHCZ-gdQ'
            },
            {
                image: MMT,
                review: 'I don’t think we have seen or used anything like Amber. Post the recent merger with GoIbibo and RedBus, I am very excited about it.',
                by: 'Deep Kalra',
                designation: 'Chairman & Group CEO',
                video_image: deep,
                video_id: 'veGWN6VaVgE'
            },
            {
                image: Genpact_logo,
                review: "We've discovered the technology to [measure employee engagement] better than just humans",
                by: 'Tiger Tyagarajan',
                designation: 'CEO',
                video_image: Tiger_Genpact,
                video_id: 'g8v4RFveRpI'
            },
            {
                image: lenskart_logo,
                review: 'A bot like Amber has the time, the patience, the memory, the empathy to understand and act on the problems.',
                by: 'Lenskart',
                designation: '',
                video_image: bbc_video,
                video_id: 'uSyqZp_7POM',
                remove_comma: true
            },
            {
                image: MYNTRA,
                review: "We've been able to prevent attrition, in a lot of cases where attrition is due to misunderstanding, especially voluntary. I think she [Amber] has really helped us.",
                by: 'Abhishek Sen',
                designation: 'Senior VP & Head - Human Capital',
                video_image: abhishek_sen,
                video_id: '7quQKIgW3s0'
            },
            {
                image: NEARBUY,
                review: '40% employees think that Amber is for real. She [Amber] genuinely becomes a way of interacting at scale.',
                by: 'Ankur Warikoo',
                designation: 'Founder & CEO',
                video_image: ankur,
                video_id: 'CHGKrDphHsY'
            },
            {
                image: JUBI,
                review: 'My personal take in this whole subject is that today, Amber is helping me prepare better for a qualitative human touch.',
                by: 'Biplob Banerjee',
                designation: 'Ex-CHRO',
                video_image: biplob,
                video_id: 'LVpZF0nAoF4'
            },
            {
                image: Airtel,
                review: "What I really like Amber is, it's a 5 to 10 minutes routine I have in the morning. It's like showing you the mirror and saying this is the reality regardless of what you believe.",
                by: 'Harmeen Mehta',
                designation: 'Global CIO',
                video_image: harmeen,
                video_id: 'ShM_HIY0Of4'
            },
            {
                image: society_generale_logo,
                review: 'Amber is a real member of my team.',
                by: 'Magesh M.S.',
                designation: 'Head HR',
                video_image: mahesh_sg,
                video_id: 'FvdRNitJBzc'
            },
            {
                image: clix_logo,
                review: "It's non-intrusive. It's almost part of the day. Does it in a manner which has very little friction to it.",
                by: 'Aditya Kohli',
                designation: 'CHRO',
                video_image: aditya_kohli_clix,
                video_id: 'f-wPge5P8Ww'
            },
            {
                image: SL,
                review: 'It’s been five month since we have done that [deployed Amber], what has been very heartening is how easily employees have taken to her.',
                by: 'Rajeev Bhardwaj',
                designation: 'CHRO',
                video_image: deep3,
                video_id: 'ePZF-RJiOj8'
            },
            {
                image: EVS_LOGO,
                review: "With the help of Amber, we know of the 4000 people, which are the top hundred cases that we want to actually really deep dive into.",
                by: 'Bhavana Kandari',
                designation: 'VP - HR',
                video_image: EvalUser,
                video_id: 'YD6Qqx1eQfE'
            },
            {
                image: Group_M,
                review: 'It is era of ROI. From a Human Capital point of view, leveraging a tool like Amber is very very useful for us.',
                by: 'Rohit Suri',
                designation: 'Chief HR & Talent Officer',
                video_image: rohit,
                video_id: 'lMxVue08nYg'
            },
            {
                image: Altimetric,
                review: "Everyday I have emails from Amber, assuring me what I know and surprising me with what I don't know.",
                by: 'Manuel Vidal',
                designation: 'Head of Uruguay Tech Center',
                video_image: manuel,
                video_id: '_5sa3AjRaNM'
            },
            {
                image: geeta_img,
                review: 'We were able to half the attrition from what we were in the previous years and … a lot of credit to adoption of an AI tool like Amber.',
                by: 'Geetha Thiagarajan',
                designation: 'Global HR leader',
                video_image: Geetha,
                video_id: 'IqLAdIStK-A'
            },
            {
                image: gradeup_logo,
                review: "Amber could surface out a lot of issues which in turn helped the leadership team and HR team to better plan the activities.",
                by: 'Himanshu Seth',
                designation: 'AVP HR',
                video_image: himanshu_gradeup,
                video_id: 'tsFCmLzU_-Q'
            },
            {
                image: PUMA,
                review: 'We launched Amber two years back. The engagement level was about 85%; the [Amber] team told us this is one of the highest engagement [standards] that they have seen in any organization.',
                by: 'Manisha Agarwal',
                designation: 'Head - HR',
                video_image: manisha,
                video_id: 'EHb0miEBIAI'
            },
            {
                image: YUM,
                review: 'Amber is nimble enough to ask questions that are relevant to the organization, relevant to geography, relevant to people, individuals. After all as HR we are in the people business and that comes first.',
                by: 'Tauseef Qadri',
                designation: 'Senior HR Leader',
                video_image: tauseef,
                video_id: 'Fg83u5w3y1k'
            },
            {
                image: IIFL,
                review: 'inFeedo-Amber has proved to be our most powerful platform to connect with 3 companies under IIFL’s parent organization.',
                by: 'Anand Mathur',
                designation: 'Group Head, HR',
                video_image: anand,
                video_id: 'Exksq_Xa9ok'
            },
            {
                image: FAREPORTAL,
                review: 'Once you get to a certain size, not having tools like Amber is a death sentence to any organisation which doesn’t have analytics built into HR.',
                by: 'Sam Jain',
                designation: 'Founder & CEO',
                video_image: sam,
                video_id: 'Kanx6KMHzmg'
            },
            // {
            //     image: KNOWLARITY,
            //     review: 'The beauty of Amber was that when you’re throwing a question, it is a conversation. The AI works beautifully. This is the glue that brings everybody together so that one plus one makes three and there’s a culture.‍',
            //     by: 'Rabia Dhody',
            //     designation: 'Head, HR',
            //     video_image: rabia,
            //     video_id: 'eA2DEEQ3I14'
            // },
        ],
        is_active: false,
        selected_video_id: ''
    }

    selectVideo = (selected_video_id) => {
        this.setState({ selected_video_id, is_active: true });
    }

    onClose = () => {
        this.setState({ is_active: false });
    }

    render() {
        const { reviews, is_active, selected_video_id } = this.state;
        return (
            <div id="testimonials">
                <br /><br className="is-hidden-mobile" /><br className="is-hidden-mobile" />
                <div className="has-text-centered pd-10">
                    <br />
                    <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold">Learn from Progressive Leaders Using Amber</h3>
                    <br />
                    <p>Stories from People Leaders Taking Engagement to the Next Level</p>
                    <br /><br />
                    <div className="columns column is-11 mar-auto has-text-left flex-wrap">
                        {reviews.map(review => (
                            <div key={review.by} className="column is-4 pd-20">
                                <StoryCard review={review} onVideoClick={this.selectVideo} />
                                <br className="is-hidden-mobile" />
                            </div>
                        ))}
                    </div>
                    {is_active ? <VideoModal video_id={selected_video_id} onClose={this.onClose} is_active={is_active} /> : null}
                </div>
            </div>
        )
    }
}
